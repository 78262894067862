#root.div {
  height: 100%
}

.is-fullheight-100vh {
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nowrap {
  white-space: nowrap;
}

ion-icon {
  color: blue;
}

span[data-tooltip].has-tooltip-link {
  border-bottom-color: transparent;
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.is-four-digit-input {
  width: 80px !important;
}

.diceTableImage {
  border-radius: 13.125rem;
}

.image.is-149x200 {
  width: 200px;
}

.noBorder {
  border: none !important;
}

.has-height-275 {
  height: 275px;
}

.width150,
.has-width-150 {
  width: 150px;
}

.has-width-50 {
  width: 50px;
}

.has-width-45 {
  width: 45px;
}

.has-width-80 {
  width: 80px;
}

.has-width-115 {
  width: 115px;
}

.has-height-150 {
  height: 150px;
  max-height: 150px;
}

.has-height-250 {
  height: 250px;
  max-height: 250px;
}

.has-height-100 {
  height: 100px;
}

.has-height-40 {
  height: 40px;
}


.has-top-border {
  border-top: 1px solid #000;
}

.has-bottom-border {
  border-bottom: 1px solid #000;
}

.has-left-border {
  border-left: 1px solid #000;
}

.has-right-border {
  border-right: 1px solid #000;
}

.has-border-1-black {
  border: 1px solid #000;
}

.has-border-1-black-rounded {
  border: 1px solid #000;
  border-radius: 5px;
}

.has-border-2-darkblue-rounded-10 {
  border: 2px rgb(64, 70, 84) solid;
  border-radius: 20px;
}

.has-border-right-1-black {
  border-right: 1px solid #000;
}

.has-border-radius-15 {
  border-radius: 15px;
}

.has-border-top-radius-15 {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.has-border-bottom-radius-15 {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.opacity-35 {
  opacity: 0.35;
}

.bgt-has-navbar-fixed-top {
  padding-top: 6em;
}

.zindex-999 {
  z-index: 999;
}

.attritionCardCenter {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0px;
  align-items: center;
  transform: translate(5px, 20px);
}

.position-relative-top-5 {
  position: relative;
  top: 5px;
}

.is-absolute {
  position: absolute;
}

.is-overlay {
    width: 100%;
    position: absolute;
    top: 0px;
    height: 100%;
    z-index: 30;
    background-color:rgba(255, 255, 255, .75);
}

.has-warning-border {
  border: 6px solid orange;
}

.bottom-expanded-menu {
  bottom: 75px;
  left: calc(100% - 80px);
  position: fixed;
  width: 80px;
  max-width: 80px;
  z-index: 100;
}

.is-rounded {
  border-radius: 5px;
}

.is-div-rounded {
  border-radius: 9999px;
  padding-top: calc(50% - 25px);
}

.has-text-in-bottom-right {
  position:absolute;
  bottom:0;
  right:0;
}